<template>
  <div class="page-container phone-book-page">
    <portal to="page-name">Cadastro de contato telefônico</portal>
    <div class="loading mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">
          Cadastro de contato telefônico
          <span v-if="form.name"> - </span>
          <small class="text-secondary">{{ form.name }}</small>
        </div>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-6 col-md-12 col-sm-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @blur="$v.form.name.$touch()"
                   placeholder="Nome" autocomplete="nope">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
        <div class="columns">
          <div class="column col-3 col-md-4 col-sm-12 form-group">
            <label for="phone" class="form-label">Telefone</label>
            <input type="text" id="phone" name="phone" v-model="form.phone"
                   class="form-input" maxlength="14"
                   placeholder="(00) 0000-0000"  autocomplete="nope" v-mask-phone.br>
          </div>
          <div class="column col-3 col-md-4 col-sm-12 form-group">
            <label for="cellphone" class="form-label">Celular</label>
            <input type="text" id="cellphone" name="cellphone"
                   v-model="form.cellphone" class="form-input" autocomplete="nope"
                   placeholder="(00) 0 0000-0000" v-mask-phone.br>
          </div>
          <div class="column col-6 col-md-4 col-sm-12 form-group">
            <label for="email" class="form-label">Email</label>
            <input type="text" id="email" name="email" v-model="form.email"
                   class="form-input"
                   placeholder="nome@email.com" autocomplete="nope">
          </div>
        </div>
        <div class="columns">
          <div class="column col-3 col-md-6 col-sm-12 form-group"
               :class="{'has-error': $v.form.address.cep.$error}">
            <label for="address-cep" class="form-label">CEP</label>
            <div class="input-group">
              <input type="text" id="address-cep" name="address-cep"
                     class="form-input" v-model="form.address.cep"
                     @blur="$v.form.address.cep.$touch()" placeholder="00.000-000"
                     @change="loadAddress" v-mask-cep autocomplete="nope">
              <button class="btn btn-neutral btn-icon btn-action input-group-btn">
                <fa-icon :icon="['fal', 'search']"></fa-icon>
              </button>
            </div>
            <template v-if="$v.form.address.cep.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.address.cep.cep">CEP inválido</div>
            </template>
          </div>
          <div class="column col-9 col-md-6 col-sm-12 form-group"></div>
          <div class="column col-9 col-md-12 col-sm-12 form-group">
            <label for="address-addressLine1" class="form-label">Logradouro</label>
            <input type="text" id="address-addressLine1" name="address-addressLine1"
                   class="form-input" v-model="form.address.addressLine1"
                   placeholder="Avenida Brasil, 1000" autocomplete="nope">
          </div>
          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <label for="address-addressLine2" class="form-label">Complemento</label>
            <input type="text" id="address-addressLine2" name="address-addressLine2"
                   class="form-input" v-model="form.address.addressLine2"
                   placeholder="casa, sobrado, loja..." autocomplete="nope">
          </div>
          <div class="column col-5 col-md-6 col-sm-12 form-group">
            <label for="address-neighborhood" class="form-label">Bairro</label>
            <input type="text" id="address-neighborhood" name="address-neighborhood"
                   placeholder="Bairro" v-model="form.address.neighborhood"
                   class="form-input" autocomplete="nope">
          </div>
          <div class="column col-4 col-md-6 col-sm-12 form-group">
            <label for="address-city" class="form-label">Cidade</label>
            <input type="text" id="address-city" name="address-city"
                   class="form-input" v-model="form.address.city"
                   placeholder="Cidade" autocomplete="nope">
          </div>
          <div class="column col-3 col-md-6 col-sm-12 form-group">
            <label for="address-state" class="form-label">Estado</label>
            <select id="address-state" name="address-state"
                    class="form-select" v-model="form.address.state">
              <option value="">[Selecione]</option>
              <option v-for="(text, value) in states"
                      :value="value" :key="value">{{ value }}</option>
            </select>
          </div>
        </div>
        <div class="columns">
          <div class="column col-12 form-group">
            <label for="notes" class="form-label">Notas</label>
            <textarea id="notes" name="notes" class="form-input" rows="4"
                      v-model="form.notes"></textarea>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                @click="save" :disabled="saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import { cep } from 'src/data/validators';
import states from 'src/data/states';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      path: '/phone-books',
      isNew: false,
      states,
      loading: false,
      loadingAddress: false,
      saving: false,
      form: this.blankForm(),
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        address: {
          cep: { cep },
        },
      },
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    async load() {
      await this.$http
        .get(`${this.path}/${this.form.id}`)
        .then(({ data }) => {
          this.form = data;
        })
        .catch(() => {});
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http
        .get(this.path, { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('Contato já adicionado', { type: 'error' });
        return;
      }

      this.saving = true;

      const phoneBook = this.clone(this.form);
      phoneBook.phone = this.onlyNumbers(phoneBook.phone);
      phoneBook.cellphone = this.onlyNumbers(phoneBook.cellphone);
      phoneBook.address.cep = this.onlyNumbers(phoneBook.address.cep);

      const request = this.isNew
        ? this.$http.post(this.path, phoneBook)
        : this.$http.put(`${this.path}/${this.form.id}`, phoneBook);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`${this.path}/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    loadAddress() {
      const cepNumber = this.onlyNumbers(this.form.address.cep);
      if (cepNumber && cepNumber.length === 8) {
        this.loadingAddress = true;
        this.$cep.get(cepNumber)
          .then((data) => {
            this.form.address.city = data.city;
            this.form.address.state = data.state;
            this.form.address.neighborhood = data.neighborhood;
            this.form.address.addressLine1 = data.street;
          })
          .catch(() => {})
          .then(() => {
            this.loadingAddress = false;
          });
      }
    },
    blankForm() {
      return {
        id: '',
        name: '',
        phone: '',
        cellphone: '',
        email: '',
        address: {
          cep: '',
          addressLine1: '',
          addressLine2: '',
          neighborhood: '',
          city: '',
          state: '',
        },
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/scss/_variables.scss";
  .insurance-page {
  }
</style>
